import { $applyNodeReplacement, $createParagraphNode, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey } from "lexical";
import React from "react";
import { Suspense } from "react";
const SlidCaptureLoadingComponent = React.lazy(() => import("./SlidCaptureLoadingComponent"));

export class SlidCaptureLoadingNode extends DecoratorNode<JSX.Element> {
  __blockID: string;

  constructor(blockID: string, key?: NodeKey) {
    super(key);
    this.__blockID = blockID;
  }

  static getType() {
    return "slid-image-capture-loading";
  }

  static clone(node: SlidCaptureLoadingNode, key?: NodeKey) {
    return new SlidCaptureLoadingNode(node.__blockID, key || node.getKey());
  }

  static importJSON() {
    // NOTE: convert to paragraph node
    return $createParagraphNode();
  }

  exportJSON() {
    return {
      blockID: this.__blockID,
      type: "slid-image-capture-loading",
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(): boolean {
    // If the inline property changes, replace the element
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <SlidCaptureLoadingComponent />
      </Suspense>
    );
  }
}

export function $createSlidCaptureLoadingNode(blockID: string) {
  const slidCaptureLoadingNode = new SlidCaptureLoadingNode(blockID);
  return $applyNodeReplacement(slidCaptureLoadingNode);
}

export function $isSlidCaptureLoadingNode(node: LexicalNode | null | undefined): node is SlidCaptureLoadingNode {
  return node instanceof SlidCaptureLoadingNode;
}
