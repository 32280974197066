import { $createSlidHeadingNode } from "components/NewEditor/nodes/SlidHeadingNode";
import { $createListNode, $isListNode, $createListItemNode } from "@lexical/list";
import { $createCodeNode } from "@lexical/code";
import { $createHorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { $createTextNode, LexicalNode } from "lexical";
import { $createSlidParagraphNode } from "../nodes/SlidParagraphNode";

const getIndentLevel = (line: string): number => {
  const indentMatch = line.match(/^\s+/);
  return indentMatch ? indentMatch[0].length / 4 : 0;
};

export const parseMarkdown = (autoNoteResult: string): LexicalNode[] => {
  const parsedResultArr: LexicalNode[] = [];
  const lines = autoNoteResult.split("\n");

  lines.forEach((line) => {
    const trimmedLine = line.trim();
    const indentLevel = getIndentLevel(line);
    const imageMatch = trimmedLine.match(/!\[([^\]]*)\]\(([^)]+)\)/);

    // checklist
    if (trimmedLine.startsWith("- [ ]")) {
      const checkListNode = $createListNode("check");
      const checkListItemNode = $createListItemNode(false);
      checkListItemNode.append($createTextNode(trimmedLine.substring(5)));
      checkListItemNode.setIndent(indentLevel);
      checkListNode.append(checkListItemNode);
      parsedResultArr.push(checkListNode);
    } else if (trimmedLine.startsWith("- [x]")) {
      const checkListNode = $createListNode("check");
      const checkListItemNode = $createListItemNode(true);
      checkListItemNode.append($createTextNode(trimmedLine.substring(5)));
      checkListItemNode.setIndent(indentLevel);
      checkListNode.append(checkListItemNode);
      parsedResultArr.push(checkListNode);
      // horizontal rule
    } else if (trimmedLine === "---" || trimmedLine === "***" || trimmedLine === "___") {
      const horizontalRuleNode = $createHorizontalRuleNode();
      parsedResultArr.push(horizontalRuleNode);
      // code block
    } else if (trimmedLine.startsWith("```")) {
      const codeNode = $createCodeNode();
      codeNode.append($createTextNode(trimmedLine.substring(3)));
      parsedResultArr.push(codeNode);
      // image
    } else if (imageMatch) {
      // NOTE: Auto notes should not return images
      const paragraphNode = $createSlidParagraphNode();
      paragraphNode.append($createTextNode(trimmedLine));
      parsedResultArr.push(paragraphNode);
      // quote
    } else if (trimmedLine.startsWith("> ")) {
      const quoteNode = $createSlidParagraphNode();
      quoteNode.append($createTextNode(trimmedLine.substring(2)));
      parsedResultArr.push(quoteNode);
      // heading
    } else if (trimmedLine.startsWith("### ")) {
      const headingNode = $createSlidHeadingNode("h3");
      headingNode.append($createTextNode(trimmedLine.substring(4)));
      parsedResultArr.push(headingNode);
    } else if (trimmedLine.startsWith("## ")) {
      const headingNode = $createSlidHeadingNode("h2");
      headingNode.append($createTextNode(trimmedLine.substring(3)));
      parsedResultArr.push(headingNode);
    } else if (trimmedLine.startsWith("# ")) {
      const headingNode = $createSlidHeadingNode("h1");
      headingNode.append($createTextNode(trimmedLine.substring(2)));
      parsedResultArr.push(headingNode);
      // list
    } else if (trimmedLine.startsWith("- ") || trimmedLine.startsWith("* ")) {
      const lastNode = parsedResultArr.at(-1);
      const listNode = $createListNode("bullet");
      const listItemNode = $createListItemNode();
      const textNode = $createTextNode(trimmedLine.substring(2));

      listItemNode.append(textNode);
      listItemNode.setIndent(indentLevel);
      listNode.append(listItemNode);

      if ($isListNode(lastNode) && indentLevel === lastNode.getIndent() && indentLevel > 0) {
        // for sibling node
        const parentNode = lastNode.getParent();
        listNode.setIndent(indentLevel - 1);
        parentNode?.append(listNode);
      } else if ($isListNode(lastNode) && lastNode.getIndent() < indentLevel) {
        // for child node
        if ($isListNode(lastNode)) {
          listNode.setIndent(indentLevel === 0 ? 0 : indentLevel - 1);
          lastNode.append(listNode);
        } else {
          parsedResultArr.push(listNode);
        }
      } else {
        // for root node
        parsedResultArr.push(listNode);
      }
      // paragraph
    } else {
      const paragraphNode = $createSlidParagraphNode();
      paragraphNode.append($createTextNode(trimmedLine));
      parsedResultArr.push(paragraphNode);
    }
  });
  return parsedResultArr;
};
