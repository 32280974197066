import { ParagraphNode } from "lexical";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { EquationNode } from "./EquationNode/EquationNode";
import { SlidImageNode } from "./SlidImageNode/ImageNode";
import { SlidHeadingNode } from "./SlidHeadingNode";
import { SlidParagraphNode } from "./SlidParagraphNode";
import { SlidImageTextExtractorNode } from "./SlidImageTextExtractorNode";
import { SlidVideoNode } from "./SlidVideoNode";
import { SlidLinkPreviewNode } from "./SlidLinkPreviewNode";
import { SlidCaptureLoadingNode } from "./SlidCaptureLoadingNode";
import { SlidAutoNoteNode } from "./SlidMobileAutoNoteNode";
import { SlidPCAutoNode } from "./SlidPCAutoNode";
import { SlidSmartLiveTextNode } from "./SlidSmartLiveTextNode";

const SlidNodes = [
  SlidHeadingNode,
  {
    replace: HeadingNode,
    with: (node: HeadingNode) => {
      return new SlidHeadingNode(node.__tag);
    },
  },
  SlidParagraphNode,
  {
    replace: ParagraphNode,
    with: (node: ParagraphNode) => {
      return new SlidParagraphNode();
    },
  },
  SlidImageTextExtractorNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  SlidVideoNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  SlidImageNode,
  EquationNode,
  HorizontalRuleNode,
  MarkNode,
  SlidLinkPreviewNode,
  SlidCaptureLoadingNode,
  SlidAutoNoteNode,
  SlidPCAutoNode,
  SlidSmartLiveTextNode,
];

export default SlidNodes;
