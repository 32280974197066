import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { addSTTData, setActiveSTTNoteBlock, setCurrentSTTData, setProcessedSTTData } from "redux/actions/sttActions";
import { useHistory } from "react-router-dom";
import { getCurrentDocumentKey } from "utils/editor/util";
import { useUpdateEffect } from "utils/customHook/customHook";
import { TranscriptBlock } from "types/smartLiveText";
import { v4 as uuidv4 } from "uuid";

import { split } from "sentence-splitter";
import { wordsCount } from "words-count";
import useEditorStore from "store/useEditorStore";
import { $createTextNode, $getRoot, $nodesOfType, LexicalNode } from "lexical";
import { $createSlidSmartLiveTextNode, SlidSmartLiveTextNode } from "components/NewEditor/nodes/SlidSmartLiveTextNode";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";

const BLOCK_MAX_WORDS = 60;
const BLOCK_MAX_CHARACTERS = 150; // or 200? for asian languages
const LANGUAGES_TO_SPLIT = ["English"];

const getMaxBlockSize = (language: string): number => {
  const characterBasedLanguages = ["한국어", "中文", "हिन्दी", "日本語"];
  const wordBasedLanguages = ["English", "Español", "Français", "Português", "Deutsch"];

  if (characterBasedLanguages.includes(language)) {
    return BLOCK_MAX_CHARACTERS;
  } else if (wordBasedLanguages.includes(language)) {
    return BLOCK_MAX_WORDS;
  } else {
    return BLOCK_MAX_WORDS;
  }
};

const shouldSplit = (language: string): boolean => {
  return LANGUAGES_TO_SPLIT.includes(language);
};

const useSLTData = () => {
  const dispatch = useAppDispatch();
  const { smartLiveTextLanguage, isSTTToggledOn, isManualAddingMode, isSTTActive, currentSTTData, activeSttNoteBlock, processedSTTData } = useAppSelector((state) => state.sttReducer);
  const { isExtensionMediaPermitted } = useAppSelector((state) => state.vdocs);
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const history = useHistory();
  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const showEditorPlaceholder = useEditorStore((state) => state.showEditorPlaceholder);
  const focusEditor = useEditorStore((state) => state.focusEditor);
  const showEditorPlaceholderRef = useRef(showEditorPlaceholder);
  const [readyToAddNewSLTBlock, setReadyToAddNewSLTBlock] = useState(false);

  const splitTranscriptIntoBlocks = (currentSTTData: TranscriptBlock) => {
    if (!shouldSplit(smartLiveTextLanguage)) return [currentSTTData];

    let blocks: TranscriptBlock[] = [];
    const sentences = split(currentSTTData.text);

    const addSentenceToLastBlock = (sentence: string) => {
      const lastBlockIndex = blocks.length - 1;
      blocks[lastBlockIndex] = {
        ...blocks[lastBlockIndex],
        text: blocks[lastBlockIndex].text + sentence,
      };
    };

    const createNewBlock = (sentence: string, isTyping = false) => {
      if (sentence.trim() === "") return;
      blocks.push({ text: sentence, isFinal: false, isTyping });
    };

    sentences.forEach((sentence) => {
      const sentenceWordCount = wordsCount(sentence.raw);
      const canAddToLastBlock = blocks.length > 0 && wordsCount(blocks[blocks.length - 1].text) + sentenceWordCount <= getMaxBlockSize(smartLiveTextLanguage);
      if (canAddToLastBlock) {
        addSentenceToLastBlock(sentence.raw);
      } else {
        createNewBlock(sentence.raw);
      }
    });

    if (blocks.length > 0) {
      blocks[blocks.length - 1].isTyping = currentSTTData.isTyping;
    }

    return blocks;
  };

  const convertActiveSLTBlockToParagraph = (data) => {
    if (!activeSttNoteBlock) return;
    const smartLiveTextLoadingNodes = lexicalEditorRef.current?.getEditorState().read(() => {
      const sltNodes = $nodesOfType(SlidSmartLiveTextNode);
      return sltNodes;
    });

    if (!smartLiveTextLoadingNodes) {
      return false;
    }

    lexicalEditorRef.current?.update(() => {
      let lastNode: LexicalNode;
      data.forEach((item, index) => {
        const newParagraphNode = $createSlidParagraphNode();
        const textNode = $createTextNode(item.text);
        newParagraphNode.append(textNode);

        if (index === 0) {
          lastNode = newParagraphNode;
          smartLiveTextLoadingNodes.forEach((sltNode) => {
            if (sltNode.__blockID === activeSttNoteBlock.id) {
              sltNode.replace(newParagraphNode);
              newParagraphNode.select();
            }
          });
        } else {
          lastNode.insertAfter(newParagraphNode);
          lastNode = newParagraphNode;
          newParagraphNode.select();
        }
      });
    });
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveSTTNoteBlock(null));
    };
  }, []);

  useUpdateEffect(() => {
    showEditorPlaceholderRef.current = showEditorPlaceholder;
  }, [showEditorPlaceholder]);

  useUpdateEffect(() => {
    // When user changes to manual mode or stops stt, convert all STT blocks to normal blocks

    const shouldConvertAllSTTBlocks = !isSTTToggledOn || (isSTTToggledOn && isManualAddingMode);

    if (!shouldConvertAllSTTBlocks) return;

    const convertSLTBlocksToParagraph = async () => {
      lexicalEditorRef.current?.update(() => {
        const smartLiveTextLoadingNodes = lexicalEditorRef.current?.getEditorState().read(() => {
          const sltNodes = $nodesOfType(SlidSmartLiveTextNode);
          return sltNodes;
        });

        if (!smartLiveTextLoadingNodes) {
          return false;
        }
        smartLiveTextLoadingNodes.forEach((sltNode) => {
          const newParagraphNode = $createSlidParagraphNode();
          // TODO: replace text data with the one from SmartLiveTextNode class method.
          const textNode = $createTextNode(processedSTTData[0].text);
          newParagraphNode.append(textNode);
          if (sltNode.__blockID === activeSttNoteBlock.id) {
            sltNode.replace(newParagraphNode);
            newParagraphNode.select();
          }
        });
      });
    };

    convertSLTBlocksToParagraph();
  }, [isManualAddingMode, isSTTToggledOn]);

  useUpdateEffect(() => {
    if (!currentSTTData || currentSTTData.length === 0) {
      dispatch(setProcessedSTTData([]));
      return;
    }
    const processedSTTData = splitTranscriptIntoBlocks(currentSTTData);
    if (currentSTTData.isFinal) {
      dispatch(addSTTData(processedSTTData));
      if (!isManualAddingMode) {
        convertActiveSLTBlockToParagraph(processedSTTData);
        setReadyToAddNewSLTBlock(true);
      }

      return;
    }
    dispatch(setProcessedSTTData(processedSTTData));
  }, [currentSTTData, isManualAddingMode]);

  useEffect(() => {
    if (isSTTActive || processedSTTData.length === 0 || !isSTTToggledOn) return;

    if (!isManualAddingMode) {
      convertActiveSLTBlockToParagraph(processedSTTData);
      setReadyToAddNewSLTBlock(true);
    }
    dispatch(setCurrentSTTData(null));
    dispatch(setProcessedSTTData([]));
  }, [isSTTActive, processedSTTData, isManualAddingMode, isSTTToggledOn]);

  useEffect(() => {
    const shouldAddNewSLTBlock = !isManualAddingMode && isSTTToggledOn && ((isExtensionMediaPermitted && applicationType === "extension") || applicationType !== "extension");
    setReadyToAddNewSLTBlock(shouldAddNewSLTBlock);
  }, [isManualAddingMode, isSTTToggledOn, isExtensionMediaPermitted, applicationType]);

  useEffect(() => {
    if (!readyToAddNewSLTBlock) return;

    const insertSttPreviewToEditor = async (activeBlockData) => {
      const insertSttPreview = async () => {
        lexicalEditorRef.current?.update(() => {
          const root = $getRoot();
          const lastChild = root.getLastChild();
          const smartLiveTextNode = $createSlidSmartLiveTextNode({ blockID: activeBlockData.id });

          if (lastChild) {
            lastChild.insertAfter(smartLiveTextNode);
          } else {
            root.append(smartLiveTextNode);
          }
        });
      };
      let currentDocumentKey = await getCurrentDocumentKey();
      if (!currentDocumentKey) return false;
      if (!window.location.pathname.includes(currentDocumentKey)) history.replace(`./${currentDocumentKey}`);
      await insertSttPreview();
      if (showEditorPlaceholderRef.current) focusEditor();
      setReadyToAddNewSLTBlock(false);
    };

    const newActiveBlockData = { id: uuidv4(), text: "", isFinal: false };
    insertSttPreviewToEditor(newActiveBlockData);
    dispatch(setActiveSTTNoteBlock(newActiveBlockData));
  }, [readyToAddNewSLTBlock]);
};

export default useSLTData;
