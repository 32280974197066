import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { KEY_DOWN_COMMAND, COMMAND_PRIORITY_LOW, $getSelection, TextNode } from "lexical";
import useEditorStore from "store/useEditorStore";
import { debounce } from "lodash";
import { SlidImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";

const HEADER_HEIGHT = 56;
function ScrollControlPlugin() {
  const [editor] = useLexicalComposerContext();
  const { editorScrollWrapperRef } = useEditorStore();

  useEffect(() => {
    return editor.registerNodeTransform(SlidImageNode, (node) => {
      if (node.isAttached()) {
        setTimeout(() => {
          const element = editor.getElementByKey(node.getKey());
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 100);
      }
    });
  }, [editor]);

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (node) => {
      if (node.isAttached()) {
        setTimeout(() => {
          debouncedHandleScroll();
        }, 100);
      }
    });
  }, [editor]);

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "Enter" || event.key === "Backspace") {
          debouncedHandleScroll();
          return false;
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregister();
    };
  }, [editor]);

  const debouncedHandleScroll = debounce(handleScroll, 100);

  function handleScroll() {
    editor.read(() => {
      const container = editorScrollWrapperRef.current;
      if (!container) return;
      const scrollPosition = container.scrollTop;
      const containerHeight = container.clientHeight;
      const currentNodeKey = $getSelection()?.getNodes()[0]?.getKey();
      if (!currentNodeKey) return;
      const currentNodeElement = editor.getElementByKey(currentNodeKey);
      const currentNodeTop = (currentNodeElement?.getBoundingClientRect().top as number) - HEADER_HEIGHT;
      const currentNodeAbsoluteTop = currentNodeTop + scrollPosition;
      const nativeSelection = window.getSelection() || null;

      let nativeSelectionTop = 0;
      // NOTE: when keyboard event is triggered on non-text node, set nativeSelectionTop to 0
      if (nativeSelection && nativeSelection.rangeCount > 0) {
        nativeSelectionTop = nativeSelection.getRangeAt(0).getBoundingClientRect().top;
      }
      const nativeSelectionAbsoluteTop = nativeSelectionTop + scrollPosition;

      const upperThreshold = containerHeight * 0.15;
      const lowerThreshold = containerHeight * 0.85;

      if (nativeSelectionTop === 0) {
        // when cursor is at an empty line, get the yPos of the node instead
        if (currentNodeTop < upperThreshold) {
          container.scrollTo({ top: currentNodeAbsoluteTop - upperThreshold });
        } else if (currentNodeTop > lowerThreshold) {
          container.scrollTo({ top: currentNodeAbsoluteTop - lowerThreshold });
        }
      } else {
        if (nativeSelectionTop < upperThreshold) {
          container.scrollTo({ top: nativeSelectionAbsoluteTop - upperThreshold });
        } else if (nativeSelectionTop > lowerThreshold) {
          container.scrollTo({ top: nativeSelectionAbsoluteTop - lowerThreshold });
        }
      }
    });
  }

  return null;
}

export default ScrollControlPlugin;
