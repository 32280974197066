import { Typography15, Typography20, Typography12 } from "@slid/slid-ips";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { setDefaultSettingCookie } from "utils/cookies/cookies";

interface SaveTimeBannerProps {
  width: number;
}

const ExtensionAnnouncementBanner = ({ width }: SaveTimeBannerProps) => {
  const { t } = useTranslation("Banner");
  const [showExtensionAnnouncementBanner, setShowExtensionAnnouncementBanner] = useState(true);

  const onClickClose = () => {
    setShowExtensionAnnouncementBanner(false);
    setDefaultSettingCookie({
      property: `showExtensionAnnouncementBanner`,
      value: false,
    });
  };

  if (!showExtensionAnnouncementBanner) {
    return null;
  }

  return (
    <BannerContainer>
      <BannerHeader>
        <Typography20 weight={400} color="--gray15" text="⚠️" />
        <Typography12 weight={500} color="--yellow1_dark" text={t("ExtensionAnnouncementBannerClose")} onClick={onClickClose} style={{ cursor: "pointer" }} />
      </BannerHeader>
      <BannerContent>
        <Typography15 weight={500} color="--gray15" text={t("ExtensionAnnouncementBanner")} />
      </BannerContent>
    </BannerContainer>
  );
};

export default ExtensionAnnouncementBanner;

const BannerContainer = styled.div`
  background-color: var(--yellow1_light);
  border-radius: 8px;
  border: 1px solid rgba(255, 202, 60, 0.12);
  padding: 16px 20px;
  margin: 8px 10px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BannerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BannerContent = styled.div``;
