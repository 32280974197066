import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { setIsRecordActive, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { setIsGettingClipRecordingMediaPermission } from "redux/actions/vdocsActions";
import { setIsGettingExtensionMediaPermission } from "redux/actions/vdocsActions";
import { trackEvent } from "utils/eventTracking";
import urls from "config/disable_clip_recording_urls.json";
import Sweetalert from "sweetalert2";
import { recordClip, stopRecordClip } from "utils/record/recordClip";
import { sendAmplitudeData } from "utils/amplitude";
import { eventTypes } from "types/eventTracking";
import useEditorStore from "store/useEditorStore";
import { $createSlidVideoRecordingNode, $isSlidVideoNode } from "components/NewEditor/nodes/SlidVideoNode";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { $getNodeByKey, $createRangeSelection, $setSelection } from "lexical";
import { useVideoRecordStore } from "./useVideoRecordStore";

const CLIP_RECORDING_MAX_SECONDS = 60;

export const useVideoRecord = () => {
  const dispatch = useAppDispatch();
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { lexicalEditorRef } = useEditorStore();
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { isExtensionMediaPermitted, currentVideo, isRecordActive, editorLastActiveBlockPosition } = useAppSelector((state) => state.vdocs);
  const { videoLoaderNodeKeys, setVideoLoaderNodeKeys } = useVideoRecordStore();
  const countdownIdRef = useRef<NodeJS.Timeout | null>(null);
  const [countdown, setCountdown] = useState(CLIP_RECORDING_MAX_SECONDS);

  const insertVideoLoader = async () => {
    lexicalEditorRef.current?.update(() => {
      const videoNode = $createSlidVideoRecordingNode();
      if (editorLastActiveBlockPosition === null) {
        $insertNodeToNearestRoot(videoNode);
      } else {
        const currentNode = $getNodeByKey(editorLastActiveBlockPosition);
        const isCurrentNodeEmpty = currentNode?.getTextContent() === "";
        if (isCurrentNodeEmpty && currentNode?.getType() !== "root") {
          currentNode.replace(videoNode);
        } else {
          $insertNodeToNearestRoot(videoNode);
        }
        const paragraphNode = $createSlidParagraphNode();
        videoNode.insertAfter(paragraphNode);
        const selection = $createRangeSelection();
        selection.anchor.set(paragraphNode.getKey(), 0, "element");
        selection.focus.set(paragraphNode.getKey(), 0, "element");
        $setSelection(selection);
        setVideoLoaderNodeKeys([...videoLoaderNodeKeys, videoNode.getKey()]);
      }
    });
  };

  const startCountdown = () => {
    if (countdown <= 0) {
      clipRecordStop();
      return;
    }
    setCountdown(countdown - 1);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isRecordActive) {
      interval = setInterval(() => {
        startCountdown();
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRecordActive, countdown]);

  const clipRecordStart = () => {
    trackEvent({
      eventType: "Check VIDEO SNIPPET PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    if (!isExtensionMediaPermitted) {
      dispatch(setIsGettingExtensionMediaPermission(true));
      dispatch(setIsGettingClipRecordingMediaPermission(true));
      dispatch(setShowExtensionMediaPermissionModal(true));
      return;
    }

    let shouldBeDisabled = false;
    urls["website_urls"].forEach((disallowedUrl) => {
      if (currentVideo?.originUrl.includes(disallowedUrl)) shouldBeDisabled = true;
    });
    if (shouldBeDisabled) {
      Sweetalert.fire({
        target: `.video-document-container`,
        heightAuto: false,
        customClass: {
          container: "position-absolute",
        },
        title: lang === "ko" ? "클립 녹화 이용불가!" : "Not available!",
        html:
          lang === "ko"
            ? `
                해당 사이트 내에서는 클립 녹화를 할 수 없습니다.
            `
            : `
                Video Snippet is not available in this website.
            `,
        icon: "warning",
        confirmButtonText: lang === "ko" ? "닫기" : "Okay",
      });
      return;
    }
    if (isRecordActive) return;
    dispatch(setIsRecordActive(true));
    insertVideoLoader();
    recordClip({
      applicationType,
    });
    sendAmplitudeData(`SLID_2_START_CLIP_RECORDING`);
    trackEvent({
      eventType: eventTypes.success.CLIP_RECORDING_START,
    });
    setCountdown(CLIP_RECORDING_MAX_SECONDS);
  };

  const clipRecordStop = async () => {
    dispatch(setIsRecordActive(false));
    clearInterval(countdownIdRef.current as NodeJS.Timeout);

    const loaderNodeKey = videoLoaderNodeKeys[0];

    if (!loaderNodeKey) return;
    lexicalEditorRef.current?.update(() => {
      const videoRecordingNode = $getNodeByKey(loaderNodeKey);

      if (videoRecordingNode && $isSlidVideoNode(videoRecordingNode)) {
        videoRecordingNode.updateVideoData({
          isLoading: true,
          isRecording: false,
        });
      }
    });

    stopRecordClip({ applicationType });
    trackEvent({
      eventType: "Click clip recording end",
    });
    setCountdown(CLIP_RECORDING_MAX_SECONDS);
  };

  return {
    clipRecordStart,
    clipRecordStop,
  };
};
