import { $createParagraphNode, DecoratorNode, DOMExportOutput, type EditorConfig, LexicalNode, NodeKey, Spread } from "lexical";
import SlidSmartLiveTextComponent from "./SlidSmartLiveTextComponent";
import React from "react";
import { SerializedDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";

export type SerializedSlidSmartLiveTextNode = Spread<
  {
    blockID: string;
  },
  SerializedDecoratorBlockNode
>;

export class SlidSmartLiveTextNode extends DecoratorNode<JSX.Element> {
  __blockID: string;
  constructor(key?: NodeKey, blockID: string = "") {
    super(key);
    this.__blockID = blockID;
  }

  static getType() {
    return "slid-smart-live-text";
  }

  static clone(node: SlidSmartLiveTextNode) {
    return new SlidSmartLiveTextNode(node.__key, node.__blockID);
  }

  static importJSON(serializedNode: SerializedSlidSmartLiveTextNode) {
    return $createParagraphNode();
  }

  exportJSON() {
    return {
      type: "slid-smart-live-text",
      blockID: this.__blockID,
      version: 1,
    };
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("div");

    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    return { element };
  }

  updateDOM(prevNode: SlidSmartLiveTextNode, dom: HTMLElement): boolean {
    return false;
  }

  isInline() {
    return false;
  }

  canBeEmpty() {
    return true;
  }

  decorate() {
    return <SlidSmartLiveTextComponent blockID={this.__blockID} />;
  }
}

export function $createSlidSmartLiveTextNode(payload: { blockID: string }) {
  return new SlidSmartLiveTextNode(undefined, payload.blockID);
}

export function $isSlidSmartLiveTextNode(node: LexicalNode | null | undefined): node is SlidSmartLiveTextNode {
  return node instanceof SlidSmartLiveTextNode;
}
