import { $getRoot, $createTextNode } from "lexical";
import { $createCodeNode } from "@lexical/code";
import { $createHorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { $createListNode, $isListNode, $isListItemNode, $createListItemNode } from "@lexical/list";
import { $createSlidParagraphNode } from "../nodes/SlidParagraphNode";
import { $createSlidHeadingNode } from "../nodes/SlidHeadingNode";
import { convertDomNodeToLexicalNode } from "./convertHTMLToLexicalNode";
import { $createEquationNode } from "../nodes/EquationNode/EquationNode";
import { $createImageNode } from "../nodes/SlidImageNode/ImageNode";
import { $createSlidImageTextExtractorNode } from "../nodes/SlidImageTextExtractorNode";
import { $createSlidVideoNode } from "../nodes/SlidVideoNode";
import { $createTableCellNode, $createTableNode, $createTableRowNode, TableCellHeaderStates } from "@lexical/table";
import { $createSlidLinkPreviewNode } from "../nodes/SlidLinkPreviewNode";

export const convertEditorJSToLexical = (editorJSData: any) => {
  const blocks = editorJSData.blocks;

  blocks
    .map((block: any, index: number) => {
      switch (block.type) {
        case "paragraph": {
          const paragraphNode = $createSlidParagraphNode();
          const childNodes = convertDomNodeToLexicalNode(block.data.text);
          childNodes.forEach((node) => {
            paragraphNode.append(node);
          });
          $getRoot().append(paragraphNode);

          break;
        }
        case "header": {
          const headerNode = $createSlidHeadingNode(`h${block.data.level - 1}`);
          const childNodes = convertDomNodeToLexicalNode(block.data.text);
          childNodes.forEach((node) => {
            headerNode.append(node);
          });
          $getRoot().append(headerNode);
          break;
        }
        case "header1": {
          const headerNode = $createSlidHeadingNode("h1");
          const childNodes = convertDomNodeToLexicalNode(block.data.text);
          childNodes.forEach((node) => {
            headerNode.append(node);
          });
          $getRoot().append(headerNode);
          break;
        }
        case "header2": {
          const headerNode = $createSlidHeadingNode("h2");
          const childNodes = convertDomNodeToLexicalNode(block.data.text);
          childNodes.forEach((node) => {
            headerNode.append(node);
          });
          $getRoot().append(headerNode);
          break;
        }
        case "header3": {
          const headerNode = $createSlidHeadingNode("h3");
          const childNodes = convertDomNodeToLexicalNode(block.data.text);
          childNodes.forEach((node) => {
            headerNode.append(node);
          });
          $getRoot().append(headerNode);
          break;
        }
        case "blockChecklist":
        case "orderedList":
        case "unorderedList": {
          const indentLevel = block.data.indentLevel;
          const childNodes = convertDomNodeToLexicalNode(block.data.items[0].text);
          const listNode = $createListNode(block.type === "orderedList" ? "number" : block.type === "unorderedList" ? "bullet" : "check");
          const listItemNode = $createListItemNode(block.type === "blockChecklist" && block.data.items[0].checked ? true : false);
          listNode.__start = block.data.numberForMarkdownDownloading;
          listNode.append(listItemNode);
          let lastListItemNode = listItemNode;
          for (let i = 0; i <= indentLevel; i++) {
            if (i === indentLevel) {
              childNodes.forEach((node) => {
                lastListItemNode.append(node);
              });
              $getRoot().append(listNode);
            } else {
              const childListNode = $createListNode(block.type === "orderedList" ? "number" : block.type === "unorderedList" ? "bullet" : "check");
              const childListItemNode = $createListItemNode(block.type === "blockChecklist" && block.data.items[0].checked ? true : false);
              childListNode.append(childListItemNode);
              $isListItemNode(lastListItemNode) && lastListItemNode.append(childListNode);
              lastListItemNode = childListItemNode;
            }
          }
          break;
        }
        case "Math": {
          const mathNode = $createEquationNode(block.data.math, false);
          $getRoot().append(mathNode);
          break;
        }
        case "image": {
          const imageNode = $createImageNode({
            sourceType: block.data.type,
            src: block.data.src,
            clipKey: block.data.clipKey,
            documentKey: block.data.documentKey,
            originalImageSrc: block.data.originalImageSrc,
            isUploaded: block.data.isUploaded,
            videoInfo: block.data.videoInfo,
            markupImageSrc: block.data.markupImageSrc ?? "",
            fullSizeImageSrcForCropping: block.data.fullSizeImageSrcForCropping ?? "",
            imageCropArea: block.data.imageCropArea ?? "",
            timestamp: block.data.timestamp ?? "",
            key: "",
            width: block.data.width,
            height: block.data.height,
            maxWidth: block.data.maxWidth,
          });
          $getRoot().append(imageNode);
          break;
        }
        case "divider": {
          const dividerNode = $createHorizontalRuleNode();
          $getRoot().append(dividerNode);
          break;
        }
        case "codeMirrorTool": {
          const codeNode = $createCodeNode(block.data.language);
          const codeContent = $createTextNode(block.data.code);
          codeNode.append(codeContent);
          $getRoot().append(codeNode);
          break;
        }
        case "codeTool": {
          const codeNode = $createSlidImageTextExtractorNode({ text: block.data.code });
          $getRoot().append(codeNode);
          break;
        }
        case "video": {
          const videoNode = $createSlidVideoNode(block.data.videoUrl, block.data.posterUrl);
          $getRoot().append(videoNode);
          break;
        }
        case "table": {
          const tableNode = $createTableNode();

          block.data.content.forEach((row: any, index: number) => {
            const tableRowNode = $createTableRowNode(row.length);
            const rowIndex = index;
            // NOTE: make the table column width dynamic based on the number of columns
            const colWidths = Array(row.length).fill(row.length < 5 ? 675 / row.length : 135);
            tableNode.setColWidths(colWidths);
            row.forEach((cell: any, index: number) => {
              const tableCellNode = $createTableCellNode(rowIndex === 0 && block.data.withHeadings ? TableCellHeaderStates.ROW : TableCellHeaderStates.NO_STATUS);
              const textNodes = convertDomNodeToLexicalNode(cell);
              textNodes.forEach((node) => {
                tableCellNode.append(node);
              });
              tableRowNode.append(tableCellNode);
            });
            tableNode.append(tableRowNode);
          });
          $getRoot().append(tableNode);
          break;
        }
        case "linkTool": {
          const linkNode = $createSlidLinkPreviewNode({ link: block.data.link, meta: block.data.meta });
          $getRoot().append(linkNode);
          break;
        }
        // S of legacy blocks
        case "blockList": {
          const indentLevel = block.data.indentLevel;
          const lastNode = $getRoot().getLastChild();
          const listNode = $createListNode(block.data.style === "unordered" ? "bullet" : "number");

          block.data.items.forEach((item: any) => {
            const listItemNode = $createListItemNode();
            const childNodes = convertDomNodeToLexicalNode(item);
            listItemNode.setIndent(indentLevel - 1);
            childNodes.forEach((node) => {
              listItemNode.append(node);
            });
            listNode.append(listItemNode);
          });

          if ($isListNode(lastNode) && indentLevel === lastNode.getIndent() && indentLevel - 1 > 0) {
            const parentNode = lastNode.getParent();
            listNode.setIndent(lastNode.getIndent());
            parentNode?.append(listNode);
          } else if ($isListNode(lastNode) && lastNode.getIndent() < indentLevel - 1) {
            listNode.setIndent(indentLevel - 2); // NOTE: this is a temporary fix
            lastNode.append(listNode);
          } else {
            $getRoot().append(listNode);
          }
          break;
        }
        // E of legacy blocks
        default:
          break;
      }
    })
    .filter(Boolean); // Make sure no null values are appended
};
