import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useRef } from "react";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import {
  sendCancelCaptureAreaRequestToParentWindow,
  sendCheckIsExtensionMediaPermitted,
  sendGoBackExtensionViewerButton,
  sendResetCaptureAreaForOneClickCaptureRequestToParentWindow,
  sendScreenCaptureRequestToParentWindow,
  sendSetFocusOnIframeRequestToParentWindow,
  sendSetSnipCaptureAreaRequestToParentWindow,
  sendVideoInfoRequestToParentWindow,
} from "utils/extensionInterface/sendToExtension";
import { showWantToContinueCapturingModal } from "utils/modal";
import { showDownloadExtensionModal } from "utils/modal";
import { useModal } from "@slid/slid-ips";
import {
  registerClip,
  registerVideo,
  setCaptureAreaRect,
  setEditorLastActiveBlockPosition,
  setIsCaptureAreaSetForOneClickCapture,
  setIsCaptureAreaActive,
  setIsCaptureAreaSetForSnipCapture,
  setIsWebCaptureAvailable,
  updateCurrentVideo,
} from "redux/actions/vdocsActions";
import { useHistory } from "react-router-dom";
import { fabric } from "fabric";
import { connectPortToExtension } from "utils/extensionInterface/connectPortToExtension";
import { setExtensionVersion } from "redux/actions/slidGlobalActions";
import { getCurrentDocumentKey } from "utils/editor/util";
import { setSnipCaptureIsDoneResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { setScreenCaptureResponseListener, setSetCurrentCaptureAreaResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { checkIfVersionIsAvailable } from "utils/etc/versionChecker";
import { $createRangeSelection, $getNodeByKey, $getSelection, $setSelection } from "lexical";
import useEditorStore from "store/useEditorStore";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $createImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { uploadBase64Image } from "utils/aws/s3Interface";
import { sendAmplitudeData } from "utils/amplitude";
import * as Sentry from "@sentry/react";
import { $createSlidParagraphNode } from "components/NewEditor/nodes/SlidParagraphNode";
import { trackEvent } from "utils/eventTracking";
import useOneClickCaptureStore from "components/VideoCaptureButton/utils/useOneClickCaptureStore";

export let shouldRedrawArea;
export let mouseDownEventsToIgnore;
export let rect;
export let initX, initY;
export let mouseX, mouseY;
export let canvas;
const WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION = "1.6.0";

const useWebExtensionCapture = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { extensionVersion, applicationType, isExtensionInstalled } = useAppSelector((state) => state.slidGlobal);
  const { isCaptureAreaSetForSnipCapture, isWebCaptureAvailable, videoPlayerRef, currentVideo, captureRect } = useAppSelector((state) => state.vdocs);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { showModal, closeModal } = useModal();
  const focusEditor = useEditorStore((state) => state.focusEditor);
  const { isCapturing, setIsCapturing } = useOneClickCaptureStore();

  const lexicalEditorRef = useEditorStore((state) => state.lexicalEditorRef);
  const externalPortRef = useRef<any>(null);
  const videoPlayerRefRef = useRef<any>(null);
  const currentVideoRef = useRef(currentVideo);

  useEffect(() => {
    currentVideoRef.current = currentVideo;
    if (applicationType === "extension" && !currentVideo) {
      sendVideoInfoRequestToParentWindow();
    }
  }, [currentVideo, applicationType]);

  useEffect(() => {
    externalPortRef.current = connectPortToExtension();
    document.addEventListener("keydown", setKeyDownListener);
    window.addEventListener("message", setMessageListenerFromVideo);

    return () => {
      document.removeEventListener("keydown", setKeyDownListener);
      window.removeEventListener("message", setMessageListenerFromVideo);
    };
  }, []);

  useEffect(() => {
    if (applicationType === "web") {
      const isExtensionVersionAvailable = checkIfVersionIsAvailable({
        requiredVersion: WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION,
        currentVersion: extensionVersion.version,
      });
      if (isExtensionVersionAvailable && externalPortRef.current) {
        dispatch(setIsWebCaptureAvailable(true));
      }
    }
  }, [extensionVersion, applicationType]);

  useEffect(() => {
    if (applicationType === "web" && isExtensionInstalled) {
      externalPortRef.current = connectPortToExtension();
      setExternalPortMessageHandler();
    }
  }, [applicationType, isExtensionInstalled]);

  useEffect(() => {
    videoPlayerRefRef.current = videoPlayerRef;
  }, [videoPlayerRef]);

  useEffect(() => {
    if (isCaptureAreaSetForSnipCapture) {
      setCanvasForSnipCapture();
    }
  }, [isCaptureAreaSetForSnipCapture]);

  useEffect(() => {
    if (applicationType === "extension") {
      sendGoBackExtensionViewerButton();
      window.parent.postMessage({ action: "IFRAME_TO_CONTENT_checkIsSlidInstantLaunched" }, "*");
      sendCheckIsExtensionMediaPermitted();
      setScreenCaptureResponseListener({
        responseHandler: captureResponseHandler,
      });

      setSetCurrentCaptureAreaResponseListener({
        responseHandler: async (receivedData) => {
          dispatch(
            setCaptureAreaRect({
              top: receivedData.rect.top - receivedData.canvasRect.top,
              left: receivedData.rect.left - receivedData.canvasRect.left,
              width: receivedData.rect.width,
              height: receivedData.rect.height,
            })
          );
          dispatch(setIsCaptureAreaActive(true));
        },
      });

      setSnipCaptureIsDoneResponseListener({
        responseHandler: async () => {
          sendSetFocusOnIframeRequestToParentWindow();
        },
      });
    }
  }, [applicationType]);

  /**
   * This method is for receiving keydown event from video which is in slid_extension.
   * @param {event} message
   */
  // TODO: fix type error
  const setMessageListenerFromVideo = (message: MessageEvent) => {
    if (message.data.action === "CONTENT_TO_IFRAME_sendKeyboardShortcutEvent") {
      const shortCutEvent = new Event("keydown");
      const { code, altKey, ctrlKey, metaKey, shiftKey } = message.data.data.event as KeyboardEvent;
      // @ts-ignore
      shortCutEvent.code = code;
      // @ts-ignore
      shortCutEvent.altKey = altKey;
      // @ts-ignore
      shortCutEvent.ctrlKey = ctrlKey;
      // @ts-ignore
      shortCutEvent.metaKey = metaKey;
      // @ts-ignore
      shortCutEvent.shiftKey = shiftKey;
      setShortcutListener(shortCutEvent);
    }
  };

  const setShortcutListener = (event) => {
    const code = event.code;
    const states = {
      alt: event.altKey,
      ctrl: event.ctrlKey,
      meta: event.metaKey,
      shift: event.shiftKey,
    };
    switch (code) {
      // short cut for manual capture (Cmd + / or Alt + /)
      case "Slash":
        if (states.alt || states.meta) {
          event.preventDefault();
          if (isCapturing) return;
          manualCapture();
          sendAmplitudeData(`Use shortcut`, {
            type: `one-click capture`,
          });
        }
        break;
      // short cut for snip capture (option + A or Alt + A)
      case "KeyA":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Use shortcut",
            eventProperties: {
              type: `snip capture`,
            },
          });
          if (applicationType === "extension") {
            if (event.altKey) {
              sendSetSnipCaptureAreaRequestToParentWindow();
            }
          } else if (applicationType === "web") {
            if (!isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: showModal,
                closeModal: closeModal,
              });
            }
            if (isWebCaptureAvailable) {
              dispatch(setIsCaptureAreaSetForSnipCapture(true));
            }
          }
        }
        break;
      // short cut for snip capture (option + X or Alt + X)
      case "KeyX":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Shortcut for setting capture area",
          });
          if (applicationType === "extension") {
            sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
          } else if (applicationType === "web") {
            if (!isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: showModal,
                closeModal: closeModal,
              });
            }
            if (isWebCaptureAvailable) {
              dispatch(setIsCaptureAreaSetForOneClickCapture(true));
            }
          }
        }
        break;
      default:
        return;
    }
  };

  const setKeyDownListener = async (event) => {
    setShortcutListener(event);
    switch (event.key) {
      case "Escape":
        sendCancelCaptureAreaRequestToParentWindow();
        setIsCapturing(false);
        break;
      default:
        break;
    }
  };

  const setExternalPortMessageHandler = () => {
    if (externalPortRef.current) {
      externalPortRef.current.onMessage.addListener((message) => {
        if (message) {
          const { action, data } = message;
          switch (action) {
            case "BACK_TO_WEB_getCurrentVersion":
              const version = data.version;
              dispatch(setExtensionVersion(version));
              break;
            case "BACK_TO_WEB_sendCaptureImg":
              captureResponseHandler(data);
              break;
            default:
              return;
          }
        }
      });
      try {
        externalPortRef.current.postMessage({
          action: "WEB_TO_BACK_getCurrentVersion",
        });
      } catch (e) {
        // If web - extension background connection is lost, reload to connect again.
        window.location.reload();
      }
    }
  };

  const getImageBlockData = async (receivedData: any, currentDocumentKey: string) => {
    let videoInfo: any = null;
    let hasVideo = true;

    // retry getting video info from extension
    if (applicationType === "extension" && !currentVideoRef.current) {
      sendVideoInfoRequestToParentWindow();
    }

    // upload base64 img to AWS S3
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: receivedData.captureImgBase64,
    });

    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;

    if (currentVideoRef.current) {
      videoInfo = {
        videoType: currentVideoRef.current.videoType,
        videoUnique: currentVideoRef.current.videoUniqueKey,
        videoOriginUrl: currentVideoRef.current.videoUrl,
        websiteOriginUrl: currentVideoRef.current.originUrl,
        videoKey: currentVideoRef.current.videoKey,
      };

      // if the video key is not here, the video was not yet registered, so register it
      if (!videoInfo.videoKey) {
        const videoRegisterInfo: any = await dispatch(
          registerVideo({
            videoInfo: currentVideoRef.current,
            documentKey: currentDocumentKey,
          })
        );

        if (videoRegisterInfo && !videoRegisterInfo.error_message) {
          videoInfo.videoKey = videoRegisterInfo["video_key"];
          await dispatch(
            updateCurrentVideo({
              videoKey: videoRegisterInfo["video_key"],
            })
          );
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
        }
      }
    }

    // to catch video registration issues in extension
    if (applicationType === "extension") {
      if (!currentVideoRef.current) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video is not set in redux, when saving a capture in extension, no attempt to register video was made");
        });
      }
      if (currentVideoRef.current && !currentVideoRef.current.videoKey) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video key is not set in redux current-video object when saving a capture in extension. Attempt to register video was made but failed");
        });
      }
    }

    // at this point if we still don't have video key, we won't save video info in img block
    if (!videoInfo || !videoInfo.videoKey) hasVideo = false;

    // save the metadata to the database
    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        clipTimestamp: receivedData.captureTime,
        documentKey: currentDocumentKey,
        videoKey: hasVideo ? videoInfo?.videoKey : null,
        clipEndPoint: "capture-clip",
      })
    );
    if (clipRegisterResponse.error_message) return;

    // return the image block data
    const imageBlockData: any = {
      src: uploadedImageSrc,
      timestamp: receivedData.captureTime,
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };
    if (hasVideo) imageBlockData.videoInfo = videoInfo;
    return imageBlockData;
  };

  const insertCapturedImage = async (currentDocumentKey: string, receivedData: any) => {
    lexicalEditorRef.current?.update(() => {
      focusEditor();
      const imageNode = $createImageNode({
        src: receivedData.captureImgBase64,
        sourceType: "manualCapture",
        clipKey: "",
        originalImageSrc: receivedData.captureImgBase64,
        isUploaded: false,
        setClipData: async () => {
          return await getImageBlockData(receivedData, currentDocumentKey);
        },
      });
      const selection = $createRangeSelection();
      const currentNodeKey = $getSelection()?.getNodes()[0]?.getKey();

      if (currentNodeKey === null || currentNodeKey === undefined) {
        $insertNodeToNearestRoot(imageNode);
      } else {
        const currentNode = $getNodeByKey(currentNodeKey);
        const currentNodeType = currentNode?.getType();
        const isCurrentNodeEmpty = currentNode?.getTextContent() === "";
        if (isCurrentNodeEmpty && currentNodeType && ["paragraph", "slid-paragraph", "list", "list-item"].includes(currentNodeType)) {
          currentNode.replace(imageNode);
          const paragraphNode = $createSlidParagraphNode();
          imageNode.insertAfter(paragraphNode);
          const paragraphNodeKey = paragraphNode.getKey();
          dispatch(setEditorLastActiveBlockPosition(paragraphNodeKey));
          selection.anchor.set(paragraphNode.getKey(), 0, "element");
          selection.focus.set(paragraphNode.getKey(), 0, "element");
          $setSelection(selection);
        } else {
          $insertNodeToNearestRoot(imageNode);
        }
      }
    });
  };

  const captureResponseHandler = async (receivedData: any) => {
    if (applicationType === "web") {
      if (videoPlayerRefRef.current) {
        // For youtube and vimeo videos
        receivedData = {
          ...receivedData,
          captureTime: videoPlayerRefRef.current.getCurrentTime(),
        };
      }
    }

    if (receivedData.captureImgBase64 === "data:,") {
      showSelectAreaPopup();
      return;
    }

    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    setIsCapturing(false);
    insertCapturedImage(currentDocumentKey, receivedData);
  };

  const handleMouseMove = (options) => {
    const generateSelectedArea = () => {
      rect = new fabric.Rect({
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        // @ts-ignore
        id: "selected-area",
        stroke: "#007bff",
        strokeWidth: 3,
        lineWidth: 3,
        strokeDashArray: [6, 3],
        fill: "rgba(255,255,255,0.3)",
        selectable: false,
        defaultCursor: "crosshair",
      });
      canvas.add(rect);
    };
    if (mouseDownEventsToIgnore > 0) {
      mouseDownEventsToIgnore--;
      return;
    }
    if (!shouldRedrawArea) {
      canvas.getObjects().forEach((object) => {
        if (object.id === "selected-area") {
          canvas.remove(object);
        }
      });
      document.querySelectorAll(".set-default-btn").forEach((btn) => btn.remove());
      document.querySelectorAll(".remove-selected-area-btn").forEach((btn) => btn.remove());
      // generate a new div on the fly
      generateSelectedArea();
      shouldRedrawArea = true;
      return;
    }
    const [newMouseX, newMouseY] = [
      options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left,
      options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top,
    ];
    [mouseX, mouseY] = [newMouseX, newMouseY];
    // check if we can resize
    if (mouseX <= 0) {
      return;
    } else if (mouseX > canvas.width) {
      return;
    }
    if (mouseY <= 0) {
      return;
    } else if (mouseY > canvas.height) {
      return;
    }
    let filteredWidth = Math.abs(mouseX - initX);
    let filteredHeight = Math.abs(mouseY - initY);
    let filteredLeft = rect.left;
    let filteredTop = rect.top;
    if (mouseX > initX) {
      filteredLeft = initX;
    } else {
      filteredLeft = mouseX;
    }
    if (mouseY < initY) {
      filteredTop = mouseY;
    } else {
      filteredTop = initY;
    }
    rect.set({
      width: filteredWidth,
      height: filteredHeight,
      left: filteredLeft,
      top: filteredTop,
    });
    canvas.renderAll();
  };

  const handleMouseUpForSnipCapture = () => {
    dispatch(setIsCaptureAreaSetForSnipCapture(false));
    const snipCaptureRect = {
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    };

    setTimeout(() => {
      // Delay snip capture while removing select area div
      if (externalPortRef.current) {
        try {
          externalPortRef.current.postMessage({
            action: "WEB_TO_BACK_captureCurrentArea",
            data: {
              captureRect: snipCaptureRect,
              videoContainerRect: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect(),
              devicePixelRatio: window.devicePixelRatio,
            },
          });
        } catch (e) {
          // If web - extension background connection is lost, reload to connect again.
          window.location.reload();
        }
      }
      canvas.off("mouse:move", handleMouseMove);
      shouldRedrawArea = false;
    }, 100);
  };

  const setCanvasForSnipCapture = async () => {
    canvas = new fabric.Canvas("slid-video-snip-capture-area", {
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
      // @ts-ignore
      id: "snip-capture-canvas",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: 0,
      top: 0,
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      // @ts-ignore
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUpForSnipCapture);
    });
  };

  const showSelectAreaPopup = () => {
    if (!confirmPrivilege(SlidFeatures.snipCapture)) return showInsufficientPrivilegeModal();
    if (applicationType === "extension") {
      // snip Capture request
      sendSetSnipCaptureAreaRequestToParentWindow();
    } else if (applicationType === "web") {
      if (!isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
      if (isWebCaptureAvailable) {
        dispatch(setIsCaptureAreaSetForSnipCapture(true));
      } else {
        showWantToContinueCapturingModal({
          showModal: showModal,
          closeModal: closeModal,
          history: history,
        });
      }
    }
  };

  const manualCapture = async () => {
    if (applicationType === "extension") {
      setIsCapturing(true);
      sendScreenCaptureRequestToParentWindow();
    } else if (applicationType === "web") {
      if (!isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
      if (isWebCaptureAvailable) {
        const videoElement =
          document.querySelector(".slid-video-player video") || // normal video
          document.querySelector(".slid-video-player iframe") || // youtube or vimeo
          document.querySelector(".slid-video-player-iframe"); // iframe video
        const rect = {
          top: 2.5,
          left: 2.5,
          width: (videoElement as HTMLElement)?.getBoundingClientRect().width - 5,
          height: (videoElement as HTMLElement)?.getBoundingClientRect().height - 5,
        };
        try {
          externalPortRef.current.postMessage({
            action: "WEB_TO_BACK_captureCurrentArea",
            data: {
              captureRect: captureRect ? captureRect : rect,
              videoContainerRect: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect(),
              devicePixelRatio: window.devicePixelRatio,
            },
          });
        } catch (e) {
          // If web - extension background connection is lost, reload to connect again.
          window.location.reload();
        }
      } else {
        showWantToContinueCapturingModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
    }
  };

  return { showSelectAreaPopup, manualCapture };
};

export default useWebExtensionCapture;
