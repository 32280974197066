import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot, TextNode, $nodesOfType, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand, LexicalEditor, LexicalNode, $setSelection } from "lexical";
import { mergeRegister } from "@lexical/utils";
import { parseMarkdown } from "components/NewEditor/utils/parseMarkdownToLexical";
import { $createSlidSmartLiveTextNode, SlidSmartLiveTextNode } from "components/NewEditor/nodes/SlidSmartLiveTextNode";

export const INSERT_SMART_LIVE_TEXT_RESULT_COMMAND: LexicalCommand<{ blockID: string | null; result: string }> = createCommand();

export const INSERT_SMART_LIVE_TEXT_BLOCK_COMMAND: LexicalCommand<{ blockID: string | null; result: string }> = createCommand();

export const REMOVE_SMART_LIVE_TEXT_BLOCK_BY_BLOCK_ID_COMMAND: LexicalCommand<{ blockID: string }> = createCommand();

export const REMOVE_ALL_SMART_LIVE_TEXT_BLOCKS_COMMAND: LexicalCommand<void> = createCommand();

export const SlidSmartLiveTextPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_SMART_LIVE_TEXT_BLOCK_COMMAND,
        (payload) => {
          const smartLiveTextBlock = $createSlidSmartLiveTextNode({ blockID: payload.blockID ?? "" });
          const lastChild = $getRoot().getLastChild();
          if (lastChild) {
            lastChild.insertAfter(smartLiveTextBlock);
            const paragraphNode = $createParagraphNode();

            smartLiveTextBlock.insertAfter(paragraphNode);
            paragraphNode.select();
          } else {
            $getRoot().append(smartLiveTextBlock);
            smartLiveTextBlock.insertAfter($createParagraphNode());
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_SMART_LIVE_TEXT_RESULT_COMMAND,
        (payload) => {
          const smartLiveTextLoadingNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidSmartLiveTextNode);
            return listNodes;
          });

          if (!smartLiveTextLoadingNodes) {
            return false;
          }

          const newSmartLiveTextBlock = $createSlidSmartLiveTextNode({ blockID: payload.blockID ?? "" });

          let lastNode: LexicalNode = newSmartLiveTextBlock;
          const parsedNodes: LexicalNode[] = parseMarkdown(payload.result);

          smartLiveTextLoadingNodes.forEach((smartLiveTextNode) => {
            if (smartLiveTextNode.__blockID === payload.blockID) {
              smartLiveTextNode.replace(newSmartLiveTextBlock);
              parsedNodes.forEach((node) => {
                lastNode.insertAfter(node);
                lastNode = node;
              });
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_SMART_LIVE_TEXT_BLOCK_BY_BLOCK_ID_COMMAND,
        (payload) => {
          const smartLiveTextNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidSmartLiveTextNode);
            return listNodes;
          });

          if (!smartLiveTextNodes) {
            return false;
          }

          smartLiveTextNodes.forEach((node) => {
            if (node.__blockID === payload.blockID) {
              node.remove();
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_ALL_SMART_LIVE_TEXT_BLOCKS_COMMAND,
        () => {
          const smartLiveTextNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidSmartLiveTextNode);
            return listNodes;
          });

          smartLiveTextNodes.forEach((node) => {
            node.remove();
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
};
