import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createSlidCaptureLoadingNode, SlidCaptureLoadingNode } from "components/NewEditor/nodes/SlidCaptureLoadingNode";
import { $nodesOfType, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils";
import { $createImageNode } from "components/NewEditor/nodes/SlidImageNode/ImageNode";

export const INSERT_LOADING_IMAGE_BLOCK_COMMAND: LexicalCommand<string> = createCommand();

export const CONVERT_LOADING_IMAGE_NODE_TO_IMAGE_COMMAND: LexicalCommand<{ src: string; blockID: string; type: string; clipKey: string; documentKey: string }> = createCommand();

export const SlidCaptureLoadingPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SlidCaptureLoadingNode])) {
      throw new Error("SlidCaptureLoadingPlugin: SlidCaptureLoadingNode is not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_LOADING_IMAGE_BLOCK_COMMAND,
        (payload) => {
          const loadingImageBlock = $createSlidCaptureLoadingNode(payload);
          $insertNodeToNearestRoot(loadingImageBlock);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        CONVERT_LOADING_IMAGE_NODE_TO_IMAGE_COMMAND,
        (payload) => {
          const loadingImageNodes = editor.getEditorState().read(() => {
            const listNodes = $nodesOfType(SlidCaptureLoadingNode);
            return listNodes;
          });

          if (!loadingImageNodes) {
            return false;
          }

          const imageBlock = $createImageNode({
            src: payload.src,
            clipKey: payload.clipKey,
            documentKey: payload.documentKey,
            originalImageSrc: payload.src,
            isUploaded: true,
            sourceType: payload.type,
            key: loadingImageNodes[0].getKey(),
          });

          loadingImageNodes.forEach((node) => {
            if (node.__blockID === payload.blockID) {
              node.replace(imageBlock);
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
};
