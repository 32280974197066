import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PlaygroundEditorTheme from "components/NewEditor/themes/PlaygroundEditorTheme";
import "./pcEditor.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import ContentEditable from "./ui/ContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import TableHoverActionsPlugin from "./plugins/TableHoverActionsPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import EquationsPlugin from "./plugins/EquationsPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import { SlidCaptureLoadingPlugin } from "./plugins/SlidCaptureLoadingPlugin";
import SlidLinkPreviewPlugin from "./plugins/SlidLinkPreviewPlugin";
import SlidNodes from "components/NewEditor/nodes/SlidNodes";
import useEditorStore from "store/useEditorStore";
import { convertEditorJSToLexical } from "./utils/convertEditorJSToLexical";
import { FlashMessageContext } from "./context/FlashMessageContext";
import { useAppDispatch, useAppSelector } from "hooks";
import { setEditorLastActiveBlockPosition, setIsEditorLoading, setIsEditorNoteLoading, setIsReadOnly } from "redux/actions/vdocsActions";
import ComponentPickerPlugin from "./plugins/ComponentPickerPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import { useSettings } from "./context/SettingsContext";
import { MaxLengthPlugin } from "./plugins/MaxLengthPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";
import { $getRoot, $getSelection, $isRangeSelection } from "lexical";
import { useLocation } from "react-router-dom";
import PCEditorPlaceholder from "./components/PCEditorPlaceholder";
import PDFFileUploader from "./components/PDFFileUploader";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { TableContext } from "./plugins/TablePlugin";
import ScrollControlPlugin from "./plugins/ScrollControlPlugin";
import SlidKeyboardPlugin from "./plugins/SlidKeyboardPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import { SlidSmartLiveTextPlugin } from "./plugins/SlidSmartLiveTextPlugin";
import { CheckListPlugin } from "./plugins/CheckListPlugin";

const PCEditor = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const { setIsFetchingDocument, currentDocumentKey, lexicalEditorRef, blurEditor } = useEditorStore();
  const { onChangeEditorState, debouncedSaveDocument, debouncedTryCreateDocumentHistory } = useSaveDocumentAndCreateHistory();
  const { currentDocument } = useAppSelector((state) => state.vdocs);
  const userData = useAppSelector((state) => state.slidGlobal.userData);
  const currentDocumentRef = useRef(currentDocument);
  const { confirmPrivilege } = useConfirmPrivilege();
  const isVideoNote = location.pathname.includes("/vdocs");

  const handleEditorBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const editorElement = event.currentTarget;
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (relatedTarget && editorElement.contains(relatedTarget)) {
      // NOTE: when focusing on checklist it tends to trigger blur event. To prevent this, we check if the relatedTarget is within the editor element.
      return;
    }

    lexicalEditorRef.current?.read(() => {
      const selection = $getSelection();
      const root = $getRoot();
      const firstChildText = root.getFirstChild()?.getTextContent();
      const firstChildType = root.getFirstChild()?.getType();
      const isEmpty = (firstChildType === "slid-paragraph" && root.getChildrenSize() === 1 && firstChildText === "") || (!firstChildText && !firstChildType);
      if ($isRangeSelection(selection)) {
        const currentNodeKey = selection.anchor.getNode().getKey();
        dispatch(setEditorLastActiveBlockPosition(currentNodeKey));
      }

      blurEditor(!isEmpty);
    });
  };

  const {
    settings: { isMaxLength, tableCellMerge, tableCellBackgroundColor, tableHorizontalScroll },
  } = useSettings();

  useEffect(() => {
    if (!currentDocumentKey) return;
    //if it's a new note, loading state is removed to show the editor right away
    const hideLoadingStateIfNewNote = () => {
      const isNewNote = location.pathname.includes("/docs/new") || location.pathname.includes("/vdocs/new") || location.pathname.includes("/demo");

      if (isNewNote) {
        setIsFetchingDocument(false);
        dispatch(setIsEditorLoading(false));
      }
    };

    const renderExistingNote = async () => {
      if (!lexicalEditorRef.current || !currentDocumentRef.current) return;
      const editor = lexicalEditorRef.current;
      const parsedNoteData = JSON.parse(currentDocumentRef.current?.content || "{}");
      const isLegacyNote = parsedNoteData.blocks;

      await new Promise<void>((resolve) => {
        editor.update(() => {
          if (isLegacyNote) {
            convertEditorJSToLexical(parsedNoteData);
          } else if (parsedNoteData) {
            if (Object.keys(parsedNoteData).length === 0) {
              const root = $getRoot();
              root.clear();
              resolve();
              return;
            }

            const editorState = editor.parseEditorState(parsedNoteData);
            if (!editorState || editorState.isEmpty()) {
              const root = $getRoot();
              root.clear();
              resolve();
              return;
            }

            editor.setEditorState(editorState);
          }
          resolve();
        });
      });

      setIsFetchingDocument(false);
    };

    // NOTE: Cancel the debounced save and history creation when the current document key is changed
    debouncedSaveDocument.cancel();
    debouncedTryCreateDocumentHistory.cancel();

    // NOTE: Clear the editor when the current document key is changed
    lexicalEditorRef.current?.update(() => {
      const root = $getRoot();
      root.clear();
    });

    hideLoadingStateIfNewNote();
    renderExistingNote();
    dispatch(setIsEditorNoteLoading(false));
    dispatch(setIsEditorLoading(false));
  }, [currentDocumentKey]);

  useEffect(() => {
    currentDocumentRef.current = currentDocument;
  }, [currentDocument]);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    // NOTE: Set editor editable based on user privilege
    if (!userData || !lexicalEditorRef.current) return;
    const isUserHasPrivilege = confirmPrivilege(SlidFeatures.noteEditing);
    lexicalEditorRef.current.setEditable(isUserHasPrivilege);
    dispatch(setIsReadOnly(!isUserHasPrivilege));
  }, [userData]);

  const initialConfig = {
    editorState: () => {},
    namespace: "Slid",
    nodes: [...SlidNodes],
    onError: (error: Error) => {
      console.error(error);
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <Container id="editor-wrapper">
      <FlashMessageContext>
        <LexicalComposer initialConfig={initialConfig} key={currentDocumentRef.current?.document_key}>
          <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
          <TableContext>
            <EditorWrapper className="editor-shell" isVideoNote={isVideoNote}>
              <RichTextPlugin
                contentEditable={
                  <div className="editor" ref={onRef} onBlur={handleEditorBlur}>
                    <ContentEditable />
                    <PCEditorPlaceholder />
                  </div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              {isMaxLength && <MaxLengthPlugin maxLength={30} />}
              <DragDropPaste />
              <EditorRefPlugin editorRef={lexicalEditorRef} />
              <OnChangePlugin onChange={onChangeEditorState} />
              <ClearEditorPlugin />

              <MarkdownShortcutPlugin />
              <TabIndentationPlugin />
              <ComponentPickerPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <TabFocusPlugin />
              <ScrollControlPlugin />
              <SlidKeyboardPlugin />
              {floatingAnchorElem && (
                <>
                  <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                  <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                  <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} isLinkEditMode={isLinkEditMode} setIsLinkEditMode={setIsLinkEditMode} />
                  <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                  <TableHoverActionsPlugin anchorElem={floatingAnchorElem} />
                  <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} setIsLinkEditMode={setIsLinkEditMode} />
                </>
              )}

              {/* Start of node plugins */}
              <CheckListPlugin />
              <ClickableLinkPlugin />
              <LinkPlugin />
              <ListPlugin />
              <TablePlugin hasCellMerge={tableCellMerge} hasCellBackgroundColor={tableCellBackgroundColor} hasHorizontalScroll={tableHorizontalScroll} />
              <TableCellResizer />
              <AutoLinkPlugin />
              <HorizontalRulePlugin />
              <ImagesPlugin />
              <EquationsPlugin />
              <CodeHighlightPlugin />
              <HistoryPlugin />
              <SlidCaptureLoadingPlugin />
              <SlidSmartLiveTextPlugin />
              <SlidLinkPreviewPlugin />
              {/* End of node plugins */}
              <PDFFileUploader />
            </EditorWrapper>
          </TableContext>
        </LexicalComposer>
      </FlashMessageContext>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditorWrapper = styled.div<{ isVideoNote: boolean }>`
  height: 100%;
  width: ${({ isVideoNote }) => (isVideoNote ? "90%" : "87%")};
  max-width: 760px;
`;

export default PCEditor;
